import { Heading } from '@troon/ui';
import { TroonGolfLandingPages } from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages
			title="Troon Golf Southeast"
			image="troon-golf-southeast-white.png"
			slugs={slugs}
			location="Southeast"
		>
			<Heading as="h2">Experience the best golf in the Southeast with Troon.</Heading>
			<p>
				Experience Troon Golf in the sunny Southeast. Troon Golf's unique collection of private and daily fee facilities
				in the Southeast are among the best in the region.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs: ComponentProps<typeof TroonGolfLandingPages>['slugs'] = {
	'arlington-ridge-golf-club': { tel: '352.805.1198' },
	'brentwood-golf-course': { tel: '904.300.2555' },
	'burnt-store-marina-country-club': { tel: '941.347.9256' },
	'cape-royal-golf-club': { tel: '239.268.7060' },
	'chateau-elan-golf-club': { tel: '678.370.7753' },
	'eagle-landing-golf-club': { tel: '904.300.2560' },
	'fairways-country-club': { tel: '407.214.8812' },
	'gateway-park-golf-course': { tel: '334.274.7013' },
	'golf-club-of-ocala': { tel: '352.619.1548' },
	'highland-park-golf-course': { tel: '205.460.7427' },
	'ibis-landing-golf-club': { tel: '239.268.7062' },
	'lagoon-park-golf-course': { tel: '334.274.7014' },
	'maggie-valley-club-and-resort': { tel: '828.285.1619' },
	'mystic-creek-golf-club': { tel: '870.474.0406' },
	'ocean-club-at-hutchinson-island': { tel: '772.238.7004' },
	'saddlebrook-golf-and-tennis-resort': { tel: '813.212.4354' },
	'stonebridge-golf-club': { tel: '706.252.3045' },
	'the-national-golf-and-country-club-at-ave-maria': { tel: '239.241.5825' },
	'the-refuge-golf-course': { tel: '769.235.1067' },
	'the-westin-savannah-harbor-golf-resort-and-spa': { tel: '912.335.6514' },
	'tiburon-golf-club': { tel: '239.241.6409' },
	'webbs-reserve-golf-club': { tel: '941.347.9264' },
	'wellen-park-golf-and-country-club': { tel: '941.335.5007' },
};

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
